
















import Vue from 'vue';
import baseOptions from '@/FirebaseUIConfig';
import firebase from 'firebase/app'
import * as firebaseui from 'firebaseui'
import AuthUI = firebaseui.auth.AuthUI;
const component = Vue.extend({
  name: "Auth",
  methods: {
    handleSignInSuccess: () => {
      try {
        console.debug(`SignIn Success`);
        // localStorage.setItem(process.env.VUE_APP_AUTH_RESULT_KEY+"", JSON.stringify(authResult))
        //When reload by returning from Google Auth flow, set to false to stop further redirect.
        return false;
      }
      catch (e) {
        console.error(`handleSignInSuccess error`, e);
      }
    },
    handleSignInFailure: (error: firebaseui.auth.AuthUIError) => {
      console.error(`Auth.vue signInFailure code: ${error.code}`);
    },
  },
  mounted() {
    let ui = AuthUI.getInstance();
    if (!ui) {
      const authObj = firebase.auth();
      authObj.languageCode = this.$i18n.locale;
      ui = new AuthUI(authObj);
    }

    try {
      let options: any = baseOptions;
      options.callbacks = {
        signInSuccessWithAuthResult: this.handleSignInSuccess,
        signInFailure: this.handleSignInFailure,
      }
      ui.start('#firebaseui-auth-container', options);
    } catch (e) {
      console.error(`Error in Auth.vue mounted`, e);
    }
  },
  beforeDestroy() {
    let ui = AuthUI.getInstance();
    if (ui) {
      ui.reset();
    }
  }
});
export default component;
