import firebase from 'firebase/app'

const baseOptions = {
    signInFlow: 'redirect',
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            scopes: [
                "https://www.googleapis.com/auth/userinfo.email",
                "https://www.googleapis.com/auth/userinfo.profile",
            ]
        },
    ],
    tosUrl: 'terms-of-service.html',
    privacyPolicyUrl: 'privacy-policy.html',
}

export default baseOptions;
